import React, {Component} from "react"
import {StaticQuery, graphql} from "gatsby"
import "flexboxgrid"
import "../styles/index.css"
import {StateProvider} from '../components/StateContext'
import SEO from "./SEO"
import Cookies from "./Cookies/Cookies"
import {getCookieAcceptance, setCookie} from "../services/service-cookies"
import {analyticsPageView} from "../services/service-analytics"
import TagManager from 'react-gtm-module'


const cookieFinanceString = 'finance';

const tagManagerArgs = {
  gtmId: 'GTM-54Q7VGB'
}

class Layout extends Component {
  constructor(props, context) 
  {
    super(props, context);

    this.setFinance = this.setFinance.bind(this);
    this.getBreadcrumbUrl = this.getBreadcrumbUrl.bind(this);

    this.state = {
      cookieFinanceString: cookieFinanceString,
      finance: getCookieAcceptance(cookieFinanceString),
      setFinance: this.setFinance,
      breadcrumbUrl: "/soeg",
      getBreadcrumbUrl: this.getBreadcrumbUrl
    }

    if (typeof window !== `undefined`) { // or typeof document !== 'undefined'

      TagManager.initialize(tagManagerArgs)
    }

   

  }

  componentDidMount() {

      setTimeout(() => {
        analyticsPageView(document.title, this.props.location.pathname);
      }, 1000) 
  }

  componentDidUpdate(prevProps) {

    if(prevProps.location.pathname !== this.props.location.pathname)
    {
      // Update breadcrumb back url
      this.setState({
        breadcrumbUrl: prevProps.location.pathname
      })

      setTimeout(() => {
        analyticsPageView(document.title, this.props.location.pathname);
      }, 1000) 
    }
  }

  setFinance(bool) {

      console.log("SetFinance", bool);

      if(typeof document !== "undefined"){

        if(bool) {
          document.body.classList.add('theme-finansk');
        } 
        else {
          document.body.classList.remove('theme-finansk');
        }
      }
      setCookie(cookieFinanceString, bool, 365);
  
      this.setState({
        finance: bool
      })

  }

  getBreadcrumbUrl() {
    return this.state.breadcrumbUrl;  
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query MetaQuery {
              site {
                siteMetadata {
                  title,
                  description,
                }
              }
            }
        `}
        render={data => {
          const {
            children
          } = this.props


          return (
            <StateProvider value={this.state}>
              <SEO
                title={data.site.siteMetadata.title}
                description={data.site.siteMetadata.description}
                location={this.props.location}
              />
 
              <main>{children}</main>

              <Cookies/>
       
            </StateProvider>
          )
        }}
      />
    )
  }
}

export default Layout
