export const analyticsPageView = (pageTitle, pathname) => {
  // console.log('analyticsPageView', pageTitle, pathname)
  window.gtag('config', window.GA_TRACKING_ID, {
    'page_title' : pageTitle,
    'page_path': pathname
  })
}

export const analyticsEvent = (category = '', action = '', label = '', value = '') => {


  console.log('analyticsEvent', category, action, label, value)

/*   const isDevEnv = Boolean(window.location.host.includes('192.168.') || window.location.host.includes('localhost') || window.location.host.includes('firebaseapp'))
  if(isDevEnv) return
 */
  window.gtag('event',
    action,
    {
    'event_category': category,
    'event_label': label,
    'value': value
    })

  /* example:
  gtag('event', 'video_auto_play_start', {
    'event_label': 'My promotional video',
    'event_category': 'video_auto_play',
    'non_interaction': true
  })
  */
}

