import React, {Component} from 'react';
import styles from './Cookies.module.css';
import {getCookieAcceptance, setCookie} from "../../services/service-cookies"
import { Link } from "@reach/router"

const cookieString = 'acceptCookies'

class Cookies extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cookiesAccepted: true
    }

    this.acceptCookies = this.acceptCookies.bind(this)
  }

  componentDidMount() {
    this.setState({
      cookiesAccepted: getCookieAcceptance(cookieString)
    })
  }

  acceptCookies(event) {
    event.preventDefault()
    setCookie(cookieString, 'true', 365)
    this.setState({
      cookiesAccepted: getCookieAcceptance(cookieString)
    })
  }

  render() {
    const {message, link, link_text} = {
      message: "Finansforbundet bruger cookies bl.a. for at huske dine indstillinger og føre statistik. Ved at bruge sitet accepterer du dette.",
      link: '//www.finansforbundet.dk/da/Sider/Privatlivspolitik.aspx',
      link_text: 'Læs mere'
    }

    if(this.state.cookiesAccepted) return null

    return (
      <div className={styles.Cookies}>
        <div className={styles.text}>
          <span
            className={styles.info}
            dangerouslySetInnerHTML={{__html: message}}
          />
          <Link to={link} className={styles.readMore}>{link_text}</Link>
        </div>
        <a href="/" className={styles.accept} onClick={this.acceptCookies}><span>&times;</span></a>
      </div>
    );
  }
}

export default Cookies;
